import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroFAQ from '../components/HeroFAQ';
import SectionFAQ from '../components/SectionFAQ';
import polylangTranslationPathHelper from '@utils/polylangTranslationPathHelper';

const Faq = ({ data, location }) => {
  const {
    wordpressPage: page,
    wordpressAcfPages: {
      acf: { faq_hero: hero, faq_questions: questions },
    },
  } = data;
  const translations = polylangTranslationPathHelper(
    page.polylang_translations
  );
  return (
    <div style={{ backgroundColor: '#F3F5F8' }}>
      <Layout
        dispatchedLang={location.state && location.state.dispatchLang}
        solidHeader="green"
        pageLang={page.polylang_current_lang}
        translations={translations}
        useTranslationsPath={true}
      >
        <SEO {...page} translations={translations} />
        <HeroFAQ {...hero} />
        {questions.map((question, idx) => (
          <SectionFAQ key={idx} {...question} />
        ))}
      </Layout>
    </div>
  );
};

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      polylang_current_lang
      polylang_translations {
        path
        polylang_current_lang
      }
      yoast_head
    }
    wordpressAcfPages(wordpress_id: { eq: $wordpress_id }) {
      acf {
        faq_hero {
          text
        }
        faq_questions {
          title
          questions {
            answer
            question
          }
        }
      }
    }
  }
`;

export default Faq;
