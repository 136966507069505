// TODO: REFACTORING
import React, { useState } from 'react';
import Style from './style.module.css';

const Accordion = ({ question, answer }) => {
  const [accOpened, setAccState] = useState(false);

  return (
    <div className={[Style.accordion, accOpened ? Style.opened : ''].join(' ')}>
      <p onClick={() => setAccState(!accOpened)} className={Style.question}>
        {question}
      </p>
      <p className={Style.answer}>{answer}</p>
    </div>
  );
};

export default Accordion;
