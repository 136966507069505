// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

const HeroFAQ = ({ text }) => {
  return (
    <section className={Style.heroFAQ}>
      <h1 className={[Style.text, 'container'].join(' ')}>{text}</h1>
    </section>
  );
};

export default HeroFAQ;
