// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import Accordion from '../Accordion';

const QuestionsList = ({ questions }) => {
  return questions.map((question, index) => {
    return <Accordion key={index} {...question} />;
  });
};

const SectionFAQ = ({ title, questions }) => {
  return (
    <section className={[Style.sectionFAQ, 'container'].join(' ')}>
      <h2 className={Style.title}>{title}</h2>
      <QuestionsList questions={questions} />
    </section>
  );
};

export default SectionFAQ;
